function transformFactToUpdateModel(fact) {
  return {
    id: fact.id,
    text: {
      en: fact.text.en,
      bg: fact.text.bg
    },
    url: fact.url
  };
}

export default {
  transformFactToUpdateModel
};
