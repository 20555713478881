<template>
  <v-card>
    <v-card-title>
      <span class="headline font-weight-light" v-if="fact.id">
        {{ $t('facts.editFact') }}
      </span>
      <span class="headline font-weight-light" v-else>
        {{ $t('facts.createFact') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form ref="manageFactForm">
        <h3 class="pb-1">{{ $t('common.text') }}</h3>
        <v-textarea
          solo
          v-model="fact.text.en"
          :rules="[rules.required]"
          :label="$t('languages.en')"
          class="pl-3"
        ></v-textarea>
        <v-textarea
          solo
          v-model="fact.text.bg"
          :rules="[rules.required]"
          :label="$t('languages.bg')"
          class="pl-3"
        ></v-textarea>
        <v-text-field
          solo
          :label="$t('common.moreInfoUrl')"
          v-model="fact.url"
          :rules="[rules.url]"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../../core/validators';

export default {
  name: 'manage-fact-modal',
  props: {
    selectedFact: Object
  },
  data() {
    return {
      fact: {
        text: {
          en: '',
          bg: ''
        },
        url: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        url: validators.isUrl(this.$i18n.t('common.invalidUrl'))
      }
    };
  },
  created() {
    if (this.selectedFact) {
      this.fact = this.selectedFact;
    }
  },
  methods: {
    async save() {
      if (!this.$refs.manageFactForm.validate()) {
        return;
      }

      this.$emit('close', this.fact);
    }
  }
};
</script>
